.App {
  text-align: center;
}

.background {
  background: #000046; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1cb5e0, #000046); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #1cb5e0,
    #000046
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.content {
}
